import { Box, Container } from "@mui/material";
import PageHero from "../../components/PageHero";
import { useStyles } from "./styles";

const Imprint = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <PageHero colorScheme="light_blue" title="Imprint" />
      <Container className={classes.mainContainer}>
        <Box className={classes.main}>
          <Box className={classes.heading} textAlign="center">
            Imprint
          </Box>

          <Box className={classes.section}>
            <Box className={classes.text}>BITA GmbH</Box>
            <Box className={classes.text}>Mainzer Landstrasse 51</Box>
            <Box className={classes.text}>60329</Box>
            <Box className={classes.text}>Frankfurt am Main</Box>
            <Box className={classes.text}>Germany</Box>
            <Box className={classes.text}>
              Register court: AG Frankfurt/Main
            </Box>
            <Box className={classes.text}>Register number: HRB 111519</Box>
          </Box>
          <Box className={classes.section}>
            <Box className={classes.text}>
              E-Mail: <a href="mailto:info@bita.io">info@bita.io</a>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Imprint;
